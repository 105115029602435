import React, { useState, useEffect } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
//import img_Car from "../img/carrito.svg";
import { __GetSessionID } from '../controles/Session';
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
//import {  Alerta } from '../controles/Utilidades';



export const Menu = ({ carChange = '' }) => {
    //var ancho = window.innerWidth;
    //var alto = window.innerHeight;
    //const [carCount, setCarCount] = useState(0);
    //const [change, setChange] = useState('');
    const navigate = useNavigate();

    const [isAdmin, setisAdmin] = useState(false);
    const [isLogged, setisLogged] = useState(false);
    //llamadas
    const API_Init = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        const res = await fetch("chome/UserData", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            if (va.login === '1') {
                if (va.isadmin === '1') { setisAdmin(true); } else { setisAdmin(false); }
                setisLogged(true);
            } else {
                setisLogged(false);
                setisAdmin(false);
            }

        }
    }
    const API_Close = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        const res = await fetch("chome/Close", { method: 'POST', body: data });
        if (res.ok) {
            // const va = await res.json();
            setisLogged(false);
            setisAdmin(false);
            navigate('/login');
        }
    }
   

    //funciones
    const onClose = () => {
        API_Close();
    }
  
    //EVENTOS
    useEffect(() => {
        API_Init();
    }, []);

    return (
        <Navbar className="bg-nav px-3" expand="lg">
            <Navbar.Brand href="#home" className="text-white" >CBOUTIQUE</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link className="text-white" href="/home">Inicio</Nav.Link>
                    {isLogged && isAdmin &&
                        <NavDropdown className="forcewhite" title="Configuracion" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/control">Control</NavDropdown.Item>
                            
                        </NavDropdown>
                    }
                </Nav>
            
                <Nav style={{ position: "relative" }}>
                    {isLogged ?
                        <Nav.Link className="text-white" onClick={onClose}>Cerrar Sesion</Nav.Link>
                        :
                        <Nav.Link className="text-white" href="/login">Iniciar Sesion</Nav.Link>
                    }

                    {/*<Nav.Link href="/carrito" style={{ position: "relative" }}><span className="text-success" style={{ position: 'absolute', left: window.innerWidth < 500 ? 12 : 20, top: 5, fontWeight: 600 }}>{carCount}</span> <img height={30} src={img_Car}></img></Nav.Link>*/}

                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
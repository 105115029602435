import { Menu } from "../controles/Menu";
import React, { useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import { LatinChar, Alerta } from '../controles/Utilidades';
import { __GetSessionID } from '../controles/Session';


//import img_No from "../img/noimage.png";
export const Home = () => {
    const [page, setPage] = useState();
    const [pagetot, setPagetot] = useState(0);
    const [productos, setProductos] = useState([]);
    const [fileLogo, setFileLogo] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showModalDel, setShowModalDel] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const rfSerch = useRef();
    const [itemSel, setItemSel] = useState({});
    //llamadas 
    const API_Init = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('page', page);
        const res = await fetch("cproductos/Init", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            if (va.isadmin === '1') { setIsAdmin(true); }
            else { setIsAdmin(false); }
            setPage(0)
            API_GetProds();
        } else {
            const asd = await res.text();
            Alerta(asd);
        }
    }
    const API_GetProds = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('page', page);
        data.append('search', rfSerch.current.value);
        const res = await fetch("cproductos/GetProds", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            const ipages = parseInt(va.pagetot);
            setPagetot(ipages);
            setProductos(va.productos);
        } else {
            const asd = await res.text();
            Alerta(asd);
        }
    }
    const API_DelLogo = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('uid', itemSel.uid);
        const res = await fetch("cproductos/DelLogo", { method: 'POST', body: data });
        if (res.ok) {
            handleCloseModal();
            API_GetProds();
        } else { Alerta(await res.text()); }
    }
    const API_UpLogo = async () => {
        if (!fileLogo) { Alerta("Seleccione una imagen"); return; }
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('uid', itemSel.uid);
        data.append('archivo', fileLogo);
        const res = await fetch("cproductos/UpLogo", { method: 'POST', body: data, contentType: fileLogo.type, contentLength: `${fileLogo.size}` });
        if (res.ok) {
            handleCloseModal();
            API_GetProds();
        } else { Alerta(await res.text()); }
    }

    //funciones
    const handleCloseModal = () => { setShowModal(false); setShowModalDel(false); }
    const handleFileLogo = (e) => {
        if (e.target.files) {
            setFileLogo(e.target.files[0]);
        }
    }
    const onbtnDelImg = (item) => {
        if (item === undefined) { Alerta('Vuelva a seleccionar el producto'); return; }
        setItemSel(item);
        setFileLogo();
        setShowModal(false);
        setShowModalDel(true);
    }
    const onbtnImgClick = (item) => {
        if (item === undefined) { Alerta('Vuelva a seleccionar el producto'); return; }
        setItemSel(item);
        setFileLogo();
        setShowModal(true);
    }
    const onDelConfirm = () => {
        API_DelLogo();
    }
    const onLoadImg = () => {
        if (!fileLogo) { Alerta('Seleccione una imagen'); return; }
        API_UpLogo();
    }
    const onClickSeek = () => {
        API_GetProds();
    }
    const onClearSeek = () => {
        rfSerch.current.value = '';
        API_GetProds();
    }

    useEffect(() => { API_Init(); }, [])
    useEffect(() => { API_GetProds(); }, [page])


    return (
        <>
            <Modal show={showModalDel} onHide={handleCloseModal}>
                <Form.Group className="mb-3" >

                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger">Borrar Imagen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card.Title>{itemSel.codigo}</Card.Title>
                        <Card.Text>{itemSel.nombre}</Card.Text>
                        <br />
                        <Form.Label>Al borrar la imagen ya no se mostrar&aacute; en el catalolo de clientes.</Form.Label>
                        <div>
                            <small className="text-success">El producto no se eliminar&aacute; de la base de datos</small>
                        </div>
                        <br />
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowModalDel(false); }}>Cerrar</Button>
                        <Button variant="primary" onClick={onDelConfirm}>Borrar</Button>
                    </Modal.Footer>
                </Form.Group>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Form.Group className="mb-3" >

                    <Modal.Header closeButton>
                        <Modal.Title>Agregar Imagen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card.Title>{itemSel.codigo}</Card.Title>
                        <Card.Text>{itemSel.nombre}</Card.Text>
                        <br />
                        <Form.Label>Seleccione un archivo de imagen.</Form.Label>
                        <div>
                            <small className="text-success">Debe ser formato JPG</small>
                        </div>
                        <br />
                        <div style={{ display: "flex", alignContent: "center", justifyContent: "flex-start", marginLeft: "15px" }}>
                            <input className="m-2" type="file" onChange={handleFileLogo} accept=".jpg" />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowModal(false); }}>Cerrar</Button>
                        <Button variant="primary" onClick={onLoadImg}>Agregar</Button>
                    </Modal.Footer>
                </Form.Group>
            </Modal>
            <Menu ></Menu>
            <Container >
                <br />
                <br />
                <Row lg={2}>
                    <div className="d-flex">
                        <div style={{ position: "relative" }} className="w100">
                            <Form.Control
                                type="search"
                                placeholder="Buscar"
                                className="me-3"
                                aria-label="Buscar"
                                ref={rfSerch}
                            >
                            </Form.Control>
                            <CloseButton className="closecros" onClick={onClearSeek} />
                        </div>
                        <div className="me-3 ml-4">
                            <Button onClick={onClickSeek} variant="primary">Buscar</Button>
                        </div>
                    </div>
                </Row>
                <br />
                <Paginacion Page={page} setPage={setPage} pageTot={pagetot} />
                <Row xs={1} md={2} lg={3} className="g-4">
                    {productos.map((item) => (

                        <Col key={item.uid}>
                            <Card className="shadow">
                                <Card.Img variant="top"
                                    src={`https://cboutique.multicar.app/cbimages/${item.imagen}.jpg`}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = 'https://cboutique.multicar.app/cbimages/noimage.jpg';
                                    }}
                                >
                                </Card.Img>
                                {isAdmin &&
                                    <div className="d-flex m-1 w100" style={{ justifyContent: 'space-evenly'} }>
                                        <div className="">
                                            <Button onClick={() => { onbtnImgClick(item) }} >Cambiar imagen</Button>
                                        </div>
                                        <div className="">
                                            <Button onClick={() => { onbtnDelImg(item) }} variant="danger" >Quitar imagen</Button>
                                        </div>
                                    </div>
                                }
                                <Card.Body>
                                    <Card.Title>{item.codigo}</Card.Title>
                                    <Card.Text>
                                        {LatinChar(item.nombre)}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <br />
                <br />
                <Paginacion Page={page} setPage={setPage} pageTot={pagetot} />
                <br />
                <br />
            </Container>
        </>
    )
}

const Paginacion = ({ pageTot = 0, Page, setPage }) => {
    const [pages, setPages] = useState([]);

    //funciones
    const calcPages = () => {
        if (pageTot <= 1) {
            return;
        }
        let cnt = [];
        let items = Page + 4;//cant de paginas 
        //if (pageTot > 0 && items >= pageTot - 1) { setPages(cnt); return; }
        if (pageTot > 0 && items >= pageTot - 1) { items = pageTot; }
        const init = Page + 1;
        for (var i = init; i < items; i++) {
            cnt.push((i + 1));
        }
        setPages(cnt);
    }
    const onNext = () => {
        const p = Page + 1;
        if (p > pageTot) { return; }
        setPage(p);
    }
    const onPrev = () => {
        const p = Page - 1;
        if (p < 0) { return; }
        setPage(p);
    }
    const onFirst = () => {
        const p = 0;
        setPage(p);
    }
    const onLast = () => {
        const p = pageTot - 1;
        setPage(p);
    }
    const onPage = (item) => {
        const p = item - 1;
        setPage(p);
    }

    useEffect(() => { calcPages(); }, [Page, pageTot])

    return (
        <Row lg={1} className="centrar">
            <Col className="flex flexAc">
                <Pagination size="lg">
                    <Pagination.First onClick={onFirst} />
                    <Pagination.Prev onClick={onPrev} />
                    {pages.map((item, index) => (
                        <Pagination.Item key={index} onClick={() => { onPage(item) }} >{item}</Pagination.Item>
                    ))}
                    <Pagination.Next onClick={onNext} />
                    <Pagination.Last onClick={onLast} />
                </Pagination>
            </Col>
        </Row>
    )

}
import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './custom.css';

import { Home } from './components/Home';
import { Login } from './components/Login';
import { Control } from './components/Control';


const App = () => {
    document.body.classList.add('fondo');

    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/control" element={<Control />} />
                </Routes>
            </div>
        </Router>
    )


}

export default App;


import { Menu } from "../controles/Menu";
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Alerta,ProgressBar } from '../controles/Utilidades';
import { Navigate } from "react-router-dom";
import { __GetSessionID } from '../controles/Session';


//import img_No from "../img/noimage.png";
export const Control = () => {
    const _myparam = { filename: '' };
    const [startCounter, setStartCounter] = useState(false);
    const [myparam, setMyparam] = useState(_myparam);
    const [errores, setErrores] = useState([]);
    const [file, setFile] = useState();
    const [authorize, setAuthorize] = useState(true);
    const [status, setStatus] = useState('');
    const [progreso, setProgreso] = useState(0);
    const [rows, setRows] = useState(0);
    //llamadas
    const API_Init = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        const res = await fetch("ccontrol/Init", { method: 'POST', body: data });
        if (res.ok) {
            //  const va = await res.json();
        } else if (res.status === 401) { setAuthorize(false); } else { alert(await res.text()); }
    }
    const API_SendFile = async () => {
        if (!file) { Alerta("Seleccione una Archivo"); return; }
        setStartCounter(true);
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('archivo', file);
        const res = await fetch("ccontrol/UpFile", { method: 'POST', body: data, contentType: file.type, contentLength: `${file.size}` });
        if (res.ok) {
            const va = await res.json();
            if (va.filename !== undefined) {
                setStatus(`Archivo Cargado`);
                setMyparam({ ...myparam, filename: va.filename });
                API_ProcessFile(va.filename);

            }else{
            setStartCounter(false);
            }

        } else if (res.status === 401) { setAuthorize(false); } else { alert("SendFile error:"+await res.text()); }
    }
    const API_ProcessFile = async (filename) => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('filename', filename);
        const res = await fetch("ccontrol/ProcessFile", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            setRows(parseFloat(va.rows));
            setProgreso(0);
            setStartCounter(true);
        } else if (res.status === 401) { setAuthorize(false); } else { alert("Procesar Error:"+await res.text()); }
    }
    const API_Progress = async () => {
        const res = await fetch("ccontrol/Progress", { method: 'GET' });
        if (res.ok) {
            const va = await res.json();
            setProgreso(parseFloat(va.counter));
            const finish = parseFloat(va.counter) >= rows;
            setStartCounter(!finish);
            setErrores(va.errores);
        } else if (res.status === 401) { setAuthorize(false); } else { alert("Progress error:"+await res.text()); }
    }
    const API_Stop = async () => {
        const res = await fetch("ccontrol/Stop", { method: 'GET' });
        if (res.ok) {
            setStartCounter(false);
        } else if (res.status === 401) { setAuthorize(false); } else { alert(await res.text()); }
    }


    //funciones 
    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };
    const onUpLoadFile = () => {
        setStatus('cargando archivo...');
        API_SendFile();
    }

    useEffect(() => { API_Init(); }, [])
    useEffect(() => {
        let interval;
        if (startCounter) {
            interval = setInterval(() => {
                API_Progress();
            }, 1000);
        }
        else {
            clearInterval(interval)
        }
        return () => clearInterval(interval);
    }, [startCounter]);
    return (
        <>{!authorize && <Navigate replace to="/" />}
            <Menu ></Menu>
            <Container >
                <br />
                <br />
                <br />
                <Row xs={1} md={2} lg={2} className="g-4 centrar">
                    <Col >
                        <Card>
                            <Card.Header>
                                <Card.Title>Subir Catalogo de productos</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="row" style={{ margin: "15px" }}>
                                    {!startCounter &&
                                        <div className="cardl-white" style={{ display: "flex", width: "100%", justifyContent: "center" }} >

                                            <div style={{ display: "flex", alignContent: "center", justifyContent: "flex-start", marginLeft: "15px" }}>
                                                <input type="file" onChange={handleFileChange} />
                                                <Button variant="success" onClick={onUpLoadFile} >Subir Archivo</Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div>
                                    {startCounter &&
                                        <div className="flex col-lg-8 flexAl flexAc flexAC">
                                            <div>
                                                <button className="btnl-red" onClick={API_Stop} >Detener</button>
                                            </div>
                                            <div >
                                                <label>Cargados {progreso} de {rows}</label>
                                            </div>
                                            <div className="col-lg-8">
                                                <ProgressBar completed={(100 * progreso) / rows} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <Card.Text className="text-danger lbtitle">
                                    {status}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


import {  RandomString } from '../controles/Utilidades';

//export const __GetSession =()=> {
//    var foo = sessionStorage.getItem('sessid');
//    if (sessionStorage.getItem('sessid') === undefined || sessionStorage.getItem('sessid') === null) {
//        sessionStorage.setItem("sessid", RandomString(16));
//    }
//    return sessionStorage.getItem('sessid');
//}
//--------------------------------------------------------manejo de sesion--------------------------------------------------------------
export const __GetSession = () => {
    if (localStorage.cbou === undefined) {
        localStorage.cbou = JSON.stringify(
            {
                id: RandomString(16),
            })
    }
    return JSON.parse(localStorage.cbou);
}
export const __GetSessionID = () => {
    if (localStorage.cbou === undefined) {
        localStorage.cbou = JSON.stringify(
            {
                id: RandomString(16),
            })
    }
    return __GetSession().id;
}